import React from 'react'
import './Overlay.css'

const Overlay = () => {
  return (
    <div className='overlay-bg'>
      {/* //Hello */}
    </div>
  )
}

export default Overlay