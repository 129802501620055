import React from 'react'
import Overlay from '../Overlay'
import Navbar from '../Navbar'
import Hero from '../Hero'
import About from '../About'
import Brand from '../Brand'
import Timeline from '../Timeline'
import Expertise from '../Expertise'
import ContactUs from '../Contact'

function Main() {
    return (
        <div>
            <Overlay />
            <Navbar />
            <Hero />
            <About />
            <Brand />
            <Timeline />
            <Expertise />
            <ContactUs />
        </div>
    );
}

export default Main;
