import React from 'react';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { GoogleReCaptchaProvider} from "react-google-recaptcha-v3"
import Main from './components/pages/Main';
import Terms from './components/pages/Terms'
import Privacy from './components/pages/Privacy'

function App() {
  return (
    <GoogleReCaptchaProvider
      language="en"
      reCaptchaKey="6LdY_8oiAAAAAFna-Hg8Bd6U8WROaUr-_j82NT_E"
    >
      <Router>
        <Routes>
          <Route exact path="/" element={<Main />} />
          <Route exact path="/terms" element={<Terms />} />
          <Route exact path="/privacy-policy" element={<Privacy />} />
        </Routes>
      </Router>
    </GoogleReCaptchaProvider>
  );
}

export default App;
