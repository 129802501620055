export const postEmail = async (data) => {

    const removeEmptyFields = (data) => {
        Object.keys(data).forEach(key => {
            if (data[key] === '' || data[key] == null) {
                delete data[key];
            }
        });

        return data;
    }

    const response = await fetch(process.env.REACT_APP_CONTACT_API_URL, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(removeEmptyFields(data))
    });

    return response;
}