import React from 'react'
import './Terms.css'

import logo from '../../img/logo.png'

function Terms() {
  return (
    <div id='terms'>
      <div className='header'>
        <nav className='navbar'>
            <a href='/' className='logo'>
                <img src={logo} alt='logo' />
            </a>
          </nav>
      </div>

      <div className='hero'>
          <div className='content'>
              <h1>BUILDING BRAND SUCCESS</h1>
              <h2>WITH DATA AND PASSION</h2>
          </div>
      </div>

      <div className='terms'>
        <div className='content'>
          <div className='terms-container-heading'>
            <h2>TERMS</h2>&nbsp;<h2>OF USE</h2>
          </div>

          <div className='txt-content'>
            <p>This Web Site is owned and maintained by Prime Online Ltd also trading as Kinetic Digital. The use and access of pages of <a href="/">https://www.primeonline.ltd</a> are subject to the terms and conditions set out below. By using this website, you agree to be legally bound by these terms and conditions.</p>
            <p>By clicking any of the other links contained on the <a href="/">https://www.primeonline.ltd</a> you accept to be bound and must refer to the directed link’s terms and conditions.</p>

            <h3>Copyright and Intellectual Property</h3>
              <p>The copyright and material on <a href="/">https://www.primeonline.ltd</a> is owned by Prime Online Ltd. Reproduction in any form of any part of the contents of this website without our prior written consent is prohibited.</p>
              <p>All intellectual property, including but not limited to trademarks, rightly belong to Prime Online Ltd or are appropriate licensed for Prime Online Ltd to use by the rightful owners, whether partners, affiliates or otherwise, any use of such therefore is strictly prohibited and shall not be copied, replicated or used in anyway unless written approval is previously provided by Prime Online Ltd.</p>
            
            <h3>Exclusion of Liability</h3>
              <p>The Information provided on Prime Online Ltd webite is believed to be reliable at the time of its publication. However, we cannot guarantee that information will be accurate, complete and current at all times. The Prime Online Ltd will not be liable for any loss or damage sustained by any user as a result of his/her reliance on information contained in this site.</p><br></br>
              <p>– Information on this website, including these terms and conditions, are subject to change by us without notification.</p><br></br>
              <p>– Access to and use of the  Prime Online Ltd website is at the user’s own risk and we do not warrant that the use of this web site or any material downloaded from it will not cause damage to any property.</p><br></br>
              <p>– This website contains links to other websites, affiliates, partners and brand(s) owned by Prime Online Ltd. Prime Online Ltd accepts no responsibility or liability for the privacy of your personal information on such web sites, as these are beyond our control.</p>
            
            <h3>Personal Data & Confidentiality</h3>
              <ol>
                <li>Prime Online Ltd is committed to comply with the legislation for the protection of personal data Law 125(I)/2018 effective implementing certain provisions of the Regulation (EE) 2016/679 of the European Parliament and of the Council of 27 April 2016 on the protection of natural persons with regard to the processing of personal data and on the free movement of such data, and repealing Directive 95/46/EC (GDPR), to take the necessary measures for the protection of personal data of visitors/users of its website.</li>
                <li>Prime Online Ltd is committed to take the appropriate technical and organizational measures for the safety of your personal data against accidental or unlawful destruction or accidental loss or alteration, prohibited access or any other unauthorized form of processing.</li>
                <li>Your personal data will be kept confidential and will be treated as such. Any information about you will not be disclosed to third parties, except in cases where it is allowed be the law.</li>
              </ol>

            <h3>Amendment of Terms & Conditions</h3>
              <p>The Prime Online Ltd may at any time amend any or all Terms and Conditions contained herein and users will be considered as having received notice and bound by the changes.</p>
            
            <h3>Breach of Terms and Conditions</h3>
              <p>In cases of breach of these Terms and Conditions by any user the company may and will take all necessary actions to protect its rights not excluding the possibility of blocking access of any user to the Site.</p>
            
            <h3>Problems experienced using our Web Site</h3>
              <p>Prime Online Ltd website has been extensively tested. However, if you experience a problem, please report it to us by email contact@primeonline.ltd.</p>
            
            <h3>Registered Address</h3>
              <p>Anapafseos 1, 3096, Limassol, Cyprus</p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Terms