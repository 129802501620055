import React, { useCallback, useState, useRef, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useGoogleReCaptcha } from "react-google-recaptcha-v3"
import ReCAPTCHA from "react-google-recaptcha";
import { contactService } from '../services';
import map from '../img/map.jpg'
import map_landscape from '../img/mobile/map-mobile-landscape.jpg'
import fb from '../img/socials/facebook.png'
import ig from '../img/socials/instagram.png'
import link from '../img/socials/linkedin.png'
import twitter from '../img/socials/twitter.png'
import './Contact.css'

import {Link} from 'react-router-dom'

const fb_link = 'https://www.facebook.com/PrimeOnlineLtd';
const ig_link = 'https://www.instagram.com/primeonlineofficial/';
const linkedin_link = 'https://www.linkedin.com/company/prime-gaming/';



const GoogleRecaptchaV2Box = ({ show, onChange }) => {
    return (
        <div className={show ? 'recaptcha-container' : 'hide-recaptcha'}>
            <div className="text">Your verification could not be completed, click on the checkbox to confirm</div>
            <ReCAPTCHA
                sitekey="6LfvweIiAAAAAHxv_WEUNaL2Yk_9g_UBrGeoacL9"
                onChange={onChange}
                hidden={false}
            />
        </div>
    )
}


const Contact = () => {

    const { register, formState: { errors }, handleSubmit } = useForm();
    const { executeRecaptcha } = useGoogleReCaptcha();
    const [postedView, setPostedView] = useState({ displayMsg: false, message: '' });
    const [recaptchaVer, setRecaptchaVer] = useState('v3');
    const [submitButtonState, setSubmitButtonState] = useState({ state: 'default', disabled: false });
    const tokenV2Ref = useRef();

    const onReCaptchaV2Change = (value) => {
        tokenV2Ref.current = value;
    }

    const onSubmit = (e) => {
        e.preventDefault();
        handleSubmit(async (data) => {
            setSubmitButtonState({ state: 'sending', disabled: true });
            if (recaptchaVer === 'v2') {
                data.token_v2 = tokenV2Ref.current;
                data.token_v3 = null;
                postContact(data, () => {
                    // todo: set message
                });
            }
            else {
                handleSubmitWithReCaptchaV3(data);
            }
        })();
    };

    const handleSubmitWithReCaptchaV3 = useCallback(async (data) => {
        if (!executeRecaptcha) {
            return;
        }

        data.token_v3 = await executeRecaptcha('submit');

        postContact(data, () => {
            setRecaptchaVer('v2');
            setSubmitButtonState({ state: 'defailt', disabled: false });
        });
    }, [executeRecaptcha]);

    const postContact = async (data, verificationFailureCallback) => {
        const response = await contactService.postEmail(data);

        if (response.status === 204) {
            setPostedView({ displayMsg: true, message: 'Thank you.' });
            setSubmitButtonState({ state: 'success', disabled: true });
        }
        else if (response.status === 400) { // V2 failure
            if (verificationFailureCallback) {
                verificationFailureCallback();
            }
        }
        else { // failure
            setPostedView({ displayMsg: true, message: 'Something went wrong. Please try again later.' });
            setSubmitButtonState({ state: 'failure', disabled: true });
        }
    };

    const className = postedView.displayMsg ? 'inputs posted' : 'inputs';

    useEffect(() => {
        if ('virtualKeyboard' in navigator) {
            navigator.virtualKeyboard.overlaysContent = true;
        }

        return () => {
            if ('virtualKeyboard' in navigator) {
                navigator.virtualKeyboard.overlaysContent = false;
            }
        }
    }, []);

    return (

        <div id="contact" className='contact'>
            <div className='container'>
                <div className='col-1'>

                    <img src={map} className='hide-mobile' useMap="#image-map" alt='Prime Online LTD'></img>

                    <map name="image-map">
                        <area target="_blank" alt="Prime Online LTD" title="Prime Online LTD" href="https://www.google.com/maps/place/Anapafseos,+Limassol,+Cyprus/@34.6894445,33.0562204,20.25z/data=!4m5!3m4!1s0x14e7336a80f4c7b5:0xce3af6fbd2cbfd46!8m2!3d34.6894901!4d33.0563325?shorturl=1" coords="255,637,519,688" shape="rect"></area>
                        <area target="_blank" alt="Prime Online LTD" title="Prime Online LTD" href="https://www.google.com/maps/place/Anapafseos,+Limassol,+Cyprus/@34.6894445,33.0562204,20.25z/data=!4m5!3m4!1s0x14e7336a80f4c7b5:0xce3af6fbd2cbfd46!8m2!3d34.6894901!4d33.0563325?shorturl=1" coords="388,733,49" shape="circle"></area>
                    </map>

                    {/* PORTRAIT */}
                    <a href="https://goo.gl/maps/UWuNWxD82stCt2ZP8" target="_blank" rel="noreferrer"><img src={map} className='hide-desktop hide-landscape' alt='Prime Online LTD'></img></a>

                    {/* LANDSCAPE */}
                    <a href="https://goo.gl/maps/UWuNWxD82stCt2ZP8" target="_blank" rel="noreferrer"><img src={map_landscape} className='hide-desktop hide-portrait' alt='Prime Online LTD'></img></a>


                </div>

                <div className='col-2'>
                    <h3>CONTACT US</h3>
                    <div className='Form'>
                        <div className={className}>
                            <form onSubmit={onSubmit} noValidate={true}>
                                <div className="input-group">
                                    <input type="text" autoComplete="name" placeholder="Your Name"
                                        {...register("name", { required: true })}
                                        aria-invalid={errors.name ? "true" : "false"}
                                    />
                                    {errors.name?.type === 'required' && <p role="alert">Name is required</p>}
                                </div>
                                <div className='column-2'>
                                    <div className="input-group">
                                        <input type="tel" autoComplete="tel" placeholder="Phone" {...register("phone", { pattern: { value: /^\+(?:[0-9\-] ?){6,14}[0-9]$/i, message: "Phone is not valid  +357-25-279200" } })} aria-invalid={errors.phone ? "true" : "false"} />
                                        <div role="alert">{errors.phone?.message}</div>
                                    </div>
                                    <div className="input-group">
                                        <input type="email" autoComplete="email" placeholder="Email Address" {...register("email", { required: "Email Address is required", pattern: { value: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/, message: "Email is not valid" } })} aria-invalid={errors.email ? "true" : "false"} />
                                        <div role="alert">{errors.email?.message}</div>
                                    </div>
                                </div>
                                <div>
                                    <textarea id="msg" placeholder="Message" name="message" {...register("message", { required: "Message Address is required" })}
                                        aria-invalid={errors.message ? "true" : "false"}></textarea>
                                    {errors.message && <p role="alert">{errors.message?.message}</p>}
                                </div>
                                <div className="input-group">
                                    This site is protected by reCAPTCHA and the Google <a href="https://policies.google.com/privacy" target="_blank">Privacy Policy</a> and <a href="https://policies.google.com/terms" target="_blank">Terms of Service</a> apply.
                                </div>
                                <GoogleRecaptchaV2Box show={recaptchaVer === 'v2'} onChange={onReCaptchaV2Change} />
                                <div className="sendmsg-container">
                                    <button type="submit" className={submitButtonState.state} disabled={submitButtonState.disabled}>
                                        <div className="caption">
                                            SEND MESSAGE
                                            <div className="spinner">
                                                <div className="bounce1"></div>
                                                <div className="bounce2"></div>
                                                <div className="bounce3"></div>
                                            </div>
                                        </div>
                                    </button>
                                    <span className="posted-msg">{postedView.message}</span>
                                </div>
                            </form>
                        </div>
                    </div> {/*class: Form */}

                    <div className='prime-details'>
                        <h3>HOW TO FIND US</h3>
                        {/* ADDRESS */}
                        <div className='container'>
                            <div className='col-1'>
                                <h4>Address</h4>
                            </div>
                            <div className='col-2'>
                                <p>Prime Online Ltd.<br></br>Anapafseos 1, Limassol, 3096<br></br>Cyprus.</p>
                            </div>
                        </div>
                        {/* PHONE */}
                        <div className='container'>
                            <div className='col-1'>
                                <h4>Phone</h4>
                            </div>
                            <div className='col-2'>
                                <a href="tel:+357-25-279200"><p>+357-25-279200</p></a>
                            </div>
                        </div>
                        {/* EMAIL */}
                        <div className='container'>
                            <div className='col-1'>
                                <h4>Email</h4>
                            </div>
                            <div className='col-2'>
                                <a href="mailto: contact@primeonline.ltd"><p>contact@primeonline.ltd</p></a>
                            </div>
                        </div>

                        <div className='soc-icons'>
                            <a href={fb_link} target="_blank" rel="noreferrer"><img src={fb} alt='Facebook' /></a>
                            <a href={ig_link} target="_blank" rel="noreferrer"><img src={ig} alt='Instagram' /></a>
                            {/* <img src={twitter} alt='Twitter' /> */}
                            <a href={linkedin_link} target="_blank" rel="noreferrer"><img src={link} alt='LinkedIn' /></a>
                        </div>
                    </div>

                    
                    <div className='footer-menu hide-mobile'>
                        <div className='container'>
                            <div className='col column-1'>
                                <Link to='/privacy-policy'>Privacy & Cookie Policy</Link>
                            </div>
                            <div className='col column-2'>
                                <Link to='/terms'>Terms Of Use</Link>
                            </div>
                            <div className='col column-3'>
                                <p>Copyright &#169; 2022, Prime Online Ltd. All Rights Reserved.</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='col-3 hide-desktop'>
                    <div className='prime-details'>
                        <h3>HOW TO FIND US</h3>
                        {/* ADDRESS */}
                        <div className='container'>
                            <div className='col-1'>
                                <h4>Address</h4>
                            </div>
                            <div className='col-2'>
                                <p>Prime Online Ltd.<br></br>Anapafseos 1, Limassol, 3096<br></br>Cyprus.</p>
                            </div>
                        </div>
                        {/* PHONE */}
                        <div className='container'>
                            <div className='col-1'>
                                <h4>Phone</h4>
                            </div>
                            <div className='col-2'>
                                <a href="tel:+357-25-279200"><p>+357-25-279200</p></a>
                            </div>
                        </div>
                        {/* EMAIL */}
                        <div className='container'>
                            <div className='col-1'>
                                <h4>Email</h4>
                            </div>
                            <div className='col-2'>
                                <a href="mailto: contact@primeonline.ltd"><p>contact@primeonline.ltd</p></a>
                            </div>
                        </div>

                        <div className='soc-icons'>
                            <a href={fb_link} target="_blank" rel="noreferrer"><img src={fb} alt='Facebook' /></a>
                            <a href={ig_link} target="_blank" rel="noreferrer"><img src={ig} alt='Instagram' /></a>
                            {/* <img src={twitter} alt='Twitter' /> */}
                            <a href={linkedin_link} target="_blank" rel="noreferrer"><img src={link} alt='LinkedIn' /></a>
                        </div>

                        <div className='footer-menu hide-desktop'>
                            <div className='container'>
                                <div className='col column-1'>
                                    <Link to='/privacy-policy'>Privacy & Cookie Policy</Link>
                                </div>
                                <div className='col column-2'>
                                    <Link to='/terms'>Terms Of Use</Link>
                                </div>
                            </div>
                            <div className='col column-3'>
                                <p>Copyright &#169; 2022, Prime Online Ltd. All Rights Reserved.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Contact