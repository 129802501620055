import React from 'react'
import './Privacy.css'

import logo from '../../img/logo.png'

function Privacy() {
  return (
    <div id="privacy">
        <div className='header'>
            <nav className='navbar'>
                <a href='/' className='logo'>
                    <img src={logo} alt='logo' />
                </a>
            </nav>
        </div>

        <div className='hero'>
            <div className='content'>
                <h1>BUILDING BRAND SUCCESS</h1>
                <h2>WITH DATA AND PASSION</h2>
            </div>
        </div>

        <div className='privacy'>
            <div className='content'>
                <div className='privacy-container-heading'>
                    <h2>PRIVACY</h2>&nbsp;<h2>POLICY</h2>
                </div>

                <div className='txt-content'>
                    <h3>Introduction</h3>
                        <p>This Privacy Policy, which was last updated on 17/11/2022, sets out the data processing practices carried out by Prime Online Ltd.</p>
                        <p>Here at Prime Online Ltd we like making things simple and easy for our customers and that includes being clear, transparent and open about what we collect, and how and why we use the data we collect, so this page goes into great detail.</p>
                        <p>We hope you will find this page helpful, but should you have any concerns or questions, regarding Data Protection, please feel free to contact our Data Protection Officer at contact@primeonline.ltd.</p>
                        <p>We only collect un-identified and non-identifiable information pertaining to you which may be made available or gathered via your use of the site. We are not aware of the identity of the user from which the Non-personal Information was collected. Non-personal Information which is being collected may include your aggregated usage information and technical information transmitted by your device, including certain software and hardware information (e.g. the type of browser and operating system your device uses, language preference, access time and the domain name of the website from which you linked to the site; etc.), in order to enhance the functionality of our site.</p>
                        <p>We may collect information about your computer, including where available your IP address, operating system and browser type, for system administration. This is statistical data about our users’ browsing actions and patterns and does not identify any individual.</p>

                    <h3>What are Cookies?</h3>
                        <p>'Cookies' are small text files stored in your web browser that enable us to recognize your computer when you visit our website. Cookies are essential to keep certain parts of our website functioning correctly and securely. We also use them to make things quicker, easier and to help us understand how our website is used.</p>
                        <p>You can choose whether to accept or reject some or all types of cookies and control this through your device’s browser settings. We will make you aware of this by showing you our cookie banner when you visit our site. If you then continue to use our website without adjusting your browser settings, we will use cookies as set out in the sections below, so to help you make an informed choice it’s important to know why we use the different types of cookie and what that means for your online experience. This section provides you with a summary of the main points and tells you how switching off the different types of cookie will affect your experience on our website.</p>

                    <h3>The Cookie Categories</h3>
                        <h4>Strictly Necessary</h4>
                            <p>This type of cookie allows you to navigate the website and use essential. These cookies don’t gather any information about you that could be used for marketing or remembering where you’ve been on the internet. These cookies are essential in our being able to guarantee the performance of our website, should you disable them we won’t be able to ensure your security or predict how our website will perform during your visit.</p>
                        <h4>Performance</h4>
                            <p>This type of cookie collects information about how you use our website e.g. which pages you visit, and if you experience any errors. The information collected is anonymous and is only used to help us improve how our website works, gauge what interests our users and assess the effectiveness of advertising.</p>
                        <h4>Functionality</h4>
                            <p>This type of cookie remembers your preferences for tools found on our website, so you don’t have to re-set them each time you visit. Some of them are managed by third parties. They may for instance determine whether you see the latest or oldest comments made in relation to an article first.</p>
                    
                    <h3>Controlling my Cookies</h3>
                        <p>All modern browsers allow you to see what cookies you have, and to clear them individually or in their entirety by changing your cookie settings. Cookie settings are typically found in the 'options' or 'preferences' menu of your browser, otherwise you should use the 'Help' option in your browser for more details.</p>
                        <p>You can block cookies by activating the settings on your browser which allows you to refuse the setting of all or some cookies. However, if you use your browser settings to block all cookies (including essential cookies) you may not be to access all or parts of our site. We endeavour to make all parts of our site accessible to everyone but if you block some cookies, particularly Strictly Necessary cookies, your access might be impaired. Unless you have adjusted your browser settings so that it will refuse cookies, our system will issue cookies as soon you visit our site.</p>
                    
                    <h3>The Use of Google Analytics</h3>
                        <p>We use Google Analytics for ‘analytical’ purposes such as to keep track of user/customer browsing patterns and to build up a profile for a customised browser experience for you. Google Analytics is a popular web analytics service provided by Google, Inc. Google Analytics uses cookies to help us to analyse how customers use our Website.</p>
                        <p>Your opt-out choice is applied only to the browser from which you make the choice, so if you use different browsers you will need to opt out in each one. This opt-out is cookie based, so if you delete your cookies, you will no longer be opted out and will need to opt out again. </p>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Privacy